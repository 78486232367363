import * as Sentry from '@sentry/nextjs'

export const calculateAspectRatio = (aspectRatio: string): number => {
	if (!aspectRatio) {
		Sentry.captureMessage('Video aspect ratio is empty. Fallback to 16:9.')
		console.warn('Video aspect ratio is empty. Fallback to 16:9.', aspectRatio)
		return 16 / 9
	}

	if (typeof aspectRatio !== 'string') {
		Sentry.captureMessage('Video aspect ratio is not a string. Fallback to 16:9.')
		console.warn('Video aspect ratio is not a string. Fallback to 16:9.', aspectRatio)
		return 16 / 9
	}

	const [width, height] =
		aspectRatio.indexOf('/') >= 0 ? aspectRatio.split('/').map(Number) : [Number(aspectRatio), 1]
	if (isNaN(width) || isNaN(height) || height === 0) {
		Sentry.captureMessage('Invalid aspect ratio. Fallback to 16:9.')
		console.warn('Invalid aspect ratio. Fallback to 16:9.', aspectRatio)
		return 16 / 9
	}
	return width / height
}
